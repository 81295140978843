
import { defineComponent, ref } from 'vue';
import Invoice from '@/components/BusinessClients/BusinessDocuments/Invoice.vue';
import { InvoiceType } from '@/models/Enums';

export default defineComponent({
    props: {
        clientId: {
            type: Number,
            required: true
        },
        invoiceId: {
            type: String,
            required: true
        }
    },
    components: {
        Invoice
    },
    async setup() {
        const invoiceType = InvoiceType;
        return { invoiceType };
    }
});
